<template>
<el-dialog
  custom-class="dialog-guide"
  title=""
  :visible.sync="dialogVisible"
  width="608px"
  :before-close="handleClose"
  :close-on-click-modal="false"
  v-if="showDialog">
  <div class="content-img-wrap">
    <img :src="contentImg" alt="" class="content-img">
  </div>
</el-dialog>
</template>
<script>
import layerMixin from '#/js/mixins/layerMixin.js'
export default {
    name:'dialogGuide',
    mixins: [layerMixin],
    props:{
        contentImg:{
            type: String
        },
        closeCallback:{
            type: Function,
            default:()=>{

            }
        },
        localStorageKey:{
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dialogVisible: true,
            showDialog: false,
            isMac: false
        }
    },
    mounted() {
    },
    methods: {
        init() {
            let endTime = '2019/09/07 00:00:00';//两周后就不会再弹出
            if(new Date(endTime).getTime()>new Date().getTime()){
                if(navigator.platform.indexOf('Win')==-1){
                    this.isMac = true;//兼容mac版safari
                }
                this.checkGuide();
            }
        },
        handleClose(){
            this.dialogVisible = false;
            this.layerHide();
            this.closeCallback();
        },
        checkGuide(){
            let localStorageKey = this.localStorageKey;
            if(localStorage.getItem(localStorageKey)){
                return;
            }

            this.layerShow(() => {
                this.showDialog = true;
            })
        }
    },
}
</script>
<style lang="scss">
.el-dialog.dialog-guide{
    position: absolute;
    border-radius: 4px;
    top: 35vh;
    left: 50%;
    transform: translate(-50%,-50%);
    .el-dialog__header{
        border-bottom: none;
    }
    .el-dialog__body{
        padding: 0;
    }
    .content-img{
        width: 100%;
        height: auto;
    }
    .content-img-wrap{
        padding-top: 10px;
        padding-bottom: 50px;
    }
}
</style>
