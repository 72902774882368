var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tracking-list" },
    [
      _c("table-wrapper", { attrs: { "table-type": "trackingList" } }),
      _c("dialog-guide", {
        attrs: {
          contentImg: _vm.tlGuideImg,
          localStorageKey: _vm.localStorageKey,
          closeCallback: _vm.handleGuide,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }