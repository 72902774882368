var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showDialog
    ? _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "dialog-guide",
            title: "",
            visible: _vm.dialogVisible,
            width: "608px",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "content-img-wrap" }, [
            _c("img", {
              staticClass: "content-img",
              attrs: { src: _vm.contentImg, alt: "" },
            }),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }