<template>
<div class="tracking-list">
    <table-wrapper :table-type="'trackingList'"></table-wrapper>
    <dialog-guide :contentImg="tlGuideImg" :localStorageKey="localStorageKey" :closeCallback="handleGuide"></dialog-guide>
</div>
</template>

<script>
    import TableWrapper from '../talent-pool/layout/tableWrapper.vue';
    import DialogGuide from '@src/component/noobGuide/dialogGuide.vue';
    import tableScrollTo from '#/js/mixins/tableScrollTo.js';

    export default {
        name: "TrackingList",
        components: {
            TableWrapper,
            DialogGuide
        },
        mixins: [tableScrollTo],
        data() {
            return {
                localStorageKey: 'trackingPoolGuide',
                tlGuideImg: require('#/assets/images/guide/img_guide_tl.png')
            }
        },
        activated() {
            this.tableScrollTo();
        },
        methods: {
            handleGuide(){
                localStorage.setItem(this.localStorageKey,true);
            }
        },
    }
</script>

<style scoped>
.tracking-list{
    height: 100%;
    overflow-y: auto;
}
</style>
